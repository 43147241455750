import '../Styling/App.css';
import React, {useState} from 'react';

function Header() {
    const [clicked, setClicked] = useState(false);
    const handleClick = () => {
        setClicked(!clicked);
    }
  return (
    
      <div className="App-header">
       <div className='logo'>
        <h1>Arber Mirena</h1>
       </div>
       <div className='Menu-icon' onClick={handleClick}>
           <i className={clicked ? 'fa fa-times' : 'fa fa-bars'}></i>
       </div>
       <div className={clicked ? 'Menu' : 'Menu close'}>
            <ul>
                <li><a href='#'>Skills</a></li>
                <li><a href='#Knowledge'>Education</a></li>
                <li><a href='#'>Projects</a></li>
                <li><a href='#'>Contact</a></li>
            </ul>
       </div>
      </div>
    
  );
}

export default Header;