import "../Styling/App.css";
import Header from "./Header";
import Salute from "./Salute";
import Knowledge from "./Knowledge";

function App() {
  return (
    <div className="mainContainer">
      <div className="App">
        <Header />
        <Salute />
        <Knowledge />
      </div>
    </div>
  );
}

export default App;
