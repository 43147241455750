import React from "react";
import "../Styling/salute.css";
import SaluteHand from '../img/saluteHand.svg';
import Rocket from '../img/RocketBuild.svg';


function Salute () {
    return (
        <div className="Salute">
            <div className="SaluteIntro">
            <h1>Hi all, I am Arber  
                <span className="waveSalute">   
                    <img src={SaluteHand} alt="Hand" />
                </span>
            </h1>  
            <p>
                A passionate Software Engineer🚀having an experience of designing and building 
                Web applications with C# as Back-End and JavaScript / Reactjs / Jquery and some other cool libraries and frameworks for the Front-End!
            </p> 
            <div className="SocialMedias">
                <a href="https://github.com/ArberMirena" target='_blank'>
                    <i class="fab fa-github giti"></i>
                </a>
                <a href="https://www.linkedin.com/in/arbermirena/" target='_blank'>
                    <i class="fab fa-linkedin linkedini"></i>
                </a>
                <a href="https://www.instagram.com/arbermirena/" target='_blank'>
                    <i class="fab fa-instagram instagrami"></i>
                </a>
                <a href="mailto:arbermirenaa@gmail.com">
                    <i class="fa fa-envelope emaili"></i>
                </a>
            </div>         
            </div>
            <div className="SaluteImage">
                <img src={Rocket} alt="rockete" />
            </div>
        </div>
    )
}

export default Salute;