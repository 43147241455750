import React, {useEffect} from "react";
import Aos from "aos";
import "aos/dist/aos.css";

import Skills from '../img/Skills.svg';
import "../Styling/Knowledge.css"



function Knowledge(){
    useEffect(() => {   
        Aos.init({ duration: 1400});
      }, [])
    return(
        <div id="Knowledge" data-aos="fade-left" className="Knowledge">
            <div id="KnowledgeImage" className="KnowledgeImage">
                <img src={Skills} alt="rockete" />
                 
            </div>
            <div className="KnowledgeIntro">
                
                <h1>What I do?</h1>  
                
                <p>
                    <span>👉</span>
                    Experience developing in .NET, using latest methodologies and best practices.
                </p>
                <br />
                <p>
                    <span>👉</span>
                    Advanced knowledge .NET Core.
                </p>
                <br />
                <p>
                    <span>👉</span>
                    Advanced knowledge in MVC (Model - View - Controller).
                </p>
                <br />
                <p>
                    <span>👉</span>
                    Experience developing and consuming REST API's in .NET.
                </p>
                <br />
                <p>
                    <span>👉</span>
                    Experience designing and developing databases in MS SQL server.
                </p>
                <br />
                <p>
                    <span>👉</span>
                    Advanced knowledge in Front-End as usual: HTML, CSS, JS, jQuery, Bootstrap.
                </p>
               
                <br />
            </div>
        </div>       
    
    )
}

export default Knowledge;